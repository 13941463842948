<template>
  <div class="Statistics_wrapper">
    <el-tabs>
      <el-tab-pane label="数据概述">
        <el-card class="box-card">
          <el-table :data="tableData" :show-header="false" border stripe style="width: 100%">
            <el-table-column prop="name"></el-table-column>
            <el-table-column prop="date"></el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="营销数据">
        <el-tabs v-model="currView" type="card">
          <el-tab-pane label="秒杀" name="Seckill"></el-tab-pane>
          <el-tab-pane label="集赞" name="Praise"></el-tab-pane>
          <el-tab-pane label="逛店" name="Store"></el-tab-pane>
          <el-tab-pane label="售券" name="Coupons"></el-tab-pane>
          <el-tab-pane label="用户奖励" name="UserAward"></el-tab-pane>
          <el-tab-pane label="羊毛党记录" name="AbNormal"></el-tab-pane>
        </el-tabs>
        <keep-alive>
          <component :is="currView" :activityID="activityID"></component>
        </keep-alive>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import StatisticsAjax from "@/utils/https/modules/Statistics.request.js";
export default {
  name: "Statistics", // 数据统计
  components: {
    Seckill: () => import("./StatisticsChildren/Seckill"),
    Praise: () => import("./StatisticsChildren/Praise"),
    Store: () => import("./StatisticsChildren/Store"),
    Coupons: () => import("./StatisticsChildren/Coupons"),
    UserAward: () => import("./StatisticsChildren/UserAward"),
    AbNormal: () => import("./StatisticsChildren/AbNormal"),
  },
  props: {},
  data() {
    return {
      tableData: [],

      currView: "Seckill",
      activityID: 0,
      SummaryDetail: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查询秒杀订单列表
    async getSummaryByActId() {
      try {
        const data = await StatisticsAjax.getSummaryByActId(this.activityID);
        this.SummaryDetail = data.data;

        for (var key in this.SummaryDetail) {
          var title = "";
          if (key == "viewNum") {
            title = "总浏览数";
            this.pushtable(key, title);
          }
          if (key == "enrollmentNum") {
            title = "总报名数";
            this.pushtable(key, title);
          }
          if (key == "cardNum") {
            title = "总售券数";
            this.pushtable(key, title);
          }
          if (key == "thumbNum") {
            title = "总集赞数";
            this.pushtable(key, title);
          }
          if (key == "goShopNum") {
            title = "总逛店数";
            this.pushtable(key, title);
          }
          if (key == "seckillOrderNum") {
            title = "总秒杀订单数";
            this.pushtable(key, title);
          }
          if (key == "liveOrderNum") {
            title = "总直播下订数";
            this.pushtable(key, title);
          }
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    pushtable(key, title) {
      this.tableData.push({
        date: this.SummaryDetail[key],
        name: title,
      });
    },
  },
  created() {
    console.log(this.$route.params.activityID);
    this.activityID = this.$route.params.activityID;
    this.getSummaryByActId();
  },
  mounted() {},
};
</script>

<style lang="scss">
.Statistics_wrapper {
  .text {
    font-size: 14px;
  }

  .item {
    padding: 18px 0;
  }

  .box-card {
    width: 480px;
    .el-card__body {
      padding: 0;
    }
  }
}
</style>
