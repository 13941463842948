import {
  post
} from "../Http";

export default {
  // 根据活动id查询 活动总计指标数据表
  getSummaryByActId: params =>
    post(`/admin/v1/summary/getByActId?actId=${params}`),

  // 秒杀:秒杀任务列表
  getQuerySeckillTaskList: params =>
    post(`/admin/v1/marketing/query_seckill_task_list`, params),
  // 秒杀:秒杀订单信息列表
  getQuerySeckillMkOrderList: params =>
    post(`/admin/v1/marketing/query_seckill_mk_order_list`, params),
  // 秒杀:导出秒杀任务列表
  getExportSeckillTaskOrderList: params =>
    post(`/admin/v1/marketing/export_seckill_task_list`, params),
  // 秒杀:导出秒杀订单列表
  getExportSeckillMkOrderList: params =>
    post(`/admin/v1/marketing/export_seckill_mk_order_list`, params),
  // 集赞:用户集赞列表
  getQueryCollectLikesList: params =>
    post(`/admin/v1/marketing/query_collect_likes_list`, params),
  // 秒杀:导出用户集赞列表
  getExportCollectLikesList: params =>
    post(`/admin/v1/marketing/export_collect_likes_list`, params),

  // 逛店:用户逛店列表
  getQueryGoShopList: params =>
    post(`/admin/v1/marketing/query_go_shop_list`, params),
  // 逛店:导出用户逛店列表
  getExportGoShopList: params =>
    post(`/admin/v1/marketing/export_go_shop_list`, params),

  // 售券:卡券订单列表
  getQueryCardOrderList: params =>
    post(`/admin/v1/marketing/query_card_order_list`, params),
  // 售券:导出卡券订单列表
  getExportCardOrderList: params =>
    post(`/admin/v1/marketing/export_card_order_list`, params),
  // 售券:优惠券核销记录
  getQueryCardCouponRecordList: params =>
    post(`/admin/v1/marketing/query_card_coupon_record_List`, params),
  // 售券:导出优惠券核销记录
  getExportCardCouponRecordList: params =>
    post(`/admin/v1/marketing/export_card_coupon_record_List`, params),

  // 用户奖励:用户奖励列表
  getQueryUserBonusList: params =>
    post(`/admin/v1/marketing/query_user_bonus_list`, params),
  // 用户奖励:导出用户奖励列表
  getExportUserBonusList: params =>
    post(`/admin/v1/marketing/export_user_bonus_list`, params),

  // 用户奖励:羊毛党列表
  getQueryEconnoisseurList: params =>
    post(`/admin/v1/marketing/query_econnoisseur_list`, params),
  // 用户奖励:导出羊毛党列表
  getExportEconnoisseurList: params =>
    post(`/admin/v1/marketing/export_econnoisseur_list`, params),
}
